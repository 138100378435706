import {
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepSeparator,
} from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';
import {
  PiNumberOneBold,
  PiNumberTwoBold,
  PiNumberThreeBold,
} from 'react-icons/pi';
import { RiDeleteBin7Line } from 'react-icons/ri';
import { TbCup } from 'react-icons/tb';

type CarouselStepperProps = {
  activeStep: number;
};

export const CarouselStepper = ({ activeStep }: CarouselStepperProps) => {
  const OneIcon = <Icon as={PiNumberOneBold} height={'80%'} width={'80%'} />;
  const TwoIcon = <Icon as={PiNumberTwoBold} height={'80%'} width={'80%'} />;
  const ThreeIcon = (
    <Icon as={PiNumberThreeBold} height={'80%'} width={'80%'} />
  );

  return (
    <Stepper
      w={'25rem'}
      maxW={'80%'}
      size={{ base: 'md', md: 'md', lg: 'lg' }}
      colorScheme='teal'
      index={activeStep}
    >
      <Step key={'step1'}>
        <StepIndicator>
          <StepStatus
            complete={OneIcon}
            incomplete={OneIcon}
            active={OneIcon}
          />
        </StepIndicator>
        <StepSeparator />
      </Step>
      <Step key={'step2'}>
        <StepIndicator>
          <StepStatus
            complete={TwoIcon}
            incomplete={TwoIcon}
            active={TwoIcon}
          />
        </StepIndicator>
        <StepSeparator />
      </Step>
      <Step key={'step3'}>
        <StepIndicator>
          <StepStatus
            complete={ThreeIcon}
            incomplete={ThreeIcon}
            active={ThreeIcon}
          />
        </StepIndicator>
        <StepSeparator />
      </Step>
    </Stepper>
  );
};
