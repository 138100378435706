import React, { ReactNode } from 'react';
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';
import Slider, { Settings } from 'react-slick';
import { Box, IconButton, Show, useBreakpointValue } from '@chakra-ui/react';

const sliderSettings: Settings = {
  centerMode: true,
  waitForAnimate: true,
  dots: false,
  arrows: false,
  fade: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerPadding: '0',
  useTransform: true,
};

type CarouselProps = {
  Cards: ReactNode[];
  handleSwipe: any;
};

export default function CaptionCarousel({ Cards, handleSwipe }: CarouselProps) {
  const [slider, setSlider] = React.useState<Slider | null>(null);

  // These are the breakpoints which changes the position of the
  // buttons as the screen size changes
  const side = useBreakpointValue({ base: '10px', md: '40px' });

  return (
    <Box
      position={'relative'}
      width={'full'}
      // overflow={'hidden'}
      // maxH={'46rem'}
    >
      {/* CSS files for react-slick */}
      <link
        rel='stylesheet'
        type='text/css'
        charSet='UTF-8'
        href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css'
      />
      <link
        rel='stylesheet'
        type='text/css'
        href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css'
      />
      <IconButton
        aria-label='left-arrow'
        position='absolute'
        left={side}
        top={'50%'}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        colorScheme='blackAlpha'
        onClick={() => {
          slider?.slickPrev();
          handleSwipe('right');
        }}
      >
        <BiLeftArrowAlt size='40px' />
      </IconButton>
      <IconButton
        aria-label='right-arrow'
        position='absolute'
        right={side}
        top={'50%'}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        colorScheme='blackAlpha'
        onClick={() => {
          slider?.slickNext();
          handleSwipe('left');
        }}
      >
        <BiRightArrowAlt size='40px' />
      </IconButton>
      <Slider
        {...sliderSettings}
        onSwipe={handleSwipe}
        ref={(slider) => setSlider(slider)}
      >
        {Cards}
      </Slider>
    </Box>
  );
}
