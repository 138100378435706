import { Heading, Box, IconButton, Icon, Image } from '@chakra-ui/react';
import {
  RiInstagramFill,
  RiFacebookCircleFill,
  RiYoutubeFill,
} from 'react-icons/ri';
import LntLogo from '../../static/images/lnt-logo-cropped.png';

const getIconProps = (url: string) => {
  return {
    backgroundColor: '#e01b2e',
    color: '#fce6bc',
    w: { base: '1.5rem', sm: '2rem', md: '2.5rem', lg: '3rem' },
    h: { base: '1.5rem', sm: '2rem', md: '2.5rem', lg: '3rem' },
    onClick: () => {
      // @ts-ignore
      window.open(url, '_blank').focus();
    },
  };
};

export const Footer = () => {
  const InstagramIcon = (
    <Icon as={RiInstagramFill} height={'100%'} width={'70%'} />
  );
  const FacebookIcon = (
    <Icon as={RiFacebookCircleFill} height={'100%'} width={'70%'} />
  );
  const YoutubeIcon = <Icon as={RiYoutubeFill} height={'100%'} width={'70%'} />;

  return (
    <>
      <Image
        src={LntLogo}
        alt={'Leave No Trace logo'}
        maxWidth={'100%'}
        width={{ base: '100%', sm: '15rem', md: '16rem', lg: '16rem' }}
      />
      <Box
        w='100%'
        h={'4rem'}
        backgroundColor={'#fcbf49'}
        zIndex={1}
        display={'flex'}
        flexDirection={'row'}
        alignItems='center'
        justifyContent={'space-between'}
      >
        <Box
          height={'100%'}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          paddingLeft={{ base: '1rem', md: '1rem', lg: '2rem' }}
        >
          <Heading
            fontSize={{ sm: 'lg', md: 'lg', lg: 'lg' }}
            fontWeight={'bolder'}
            color={'#e01b2e'}
          >
            #LeaveNoTraceBrighton
          </Heading>
        </Box>

        <Box
          // bg='red'
          height={'100%'}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          paddingRight={{ base: '1rem', md: '1rem', lg: '2rem' }}
          gap='0.5rem'
        >
          <IconButton
            aria-label='Instagram button'
            icon={InstagramIcon}
            {...getIconProps('https://www.instagram.com/leavenotracebrighton/')}
          />
          <IconButton
            aria-label='Facebook button'
            icon={FacebookIcon}
            {...getIconProps('https://www.facebook.com/leavenotracebrighton')}
          />
          <IconButton
            aria-label='Youtube button'
            icon={YoutubeIcon}
            {...getIconProps('https://www.youtube.com/watch?v=miQ8aWGEIvk')}
          />
        </Box>
      </Box>
    </>
  );
};
