import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Stack,
  Heading,
  UnorderedList,
  ListItem,
  Divider,
  Box,
} from '@chakra-ui/react';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

type ModalProps = {
  isOpen: boolean;
  onOpen?: any;
  onClose: any;
};

const renderShareButtons = () => {
  const shareUrl = 'pledgetoleavenotracebrighton.co.uk';
  const hashtags = ['#Take3ForTheSea', '#LeaveNoTraceBrighton'];
  const title = 'I am taking a pledge to leave no trace in my Brighton visit.';
  const body = `${title}\n\nVisit the page at ${shareUrl} to take learn more and take part!\n\n`;
  const bodyWithHashtags = `${body}${hashtags.join(' ')}\n\n`;

  const iconProps = {
    size: 44,
    round: true,
  };

  return (
    <Stack
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'center'}
      w='70%'
      maxW={'100vw'}
      justifyItems={'center'}
      alignItems={'center'}
      alignContent={'center'}
      flexWrap={'wrap'}
      gap={{ base: '0.5rem', md: '1rem' }}
      mt='1rem'
      mb='1rem'
      marginX='auto'
    >
      <FacebookShareButton
        quote={title}
        hashtag={hashtags.join(' ')}
        url={shareUrl}
      >
        <FacebookIcon {...iconProps} />
      </FacebookShareButton>
      <FacebookMessengerShareButton url={shareUrl} appId='223976426258682'>
        <FacebookMessengerIcon {...iconProps} />
      </FacebookMessengerShareButton>
      <TwitterShareButton
        url={shareUrl}
        title={body}
        hashtags={['#Take3ForTheSea', '#LeaveNoTraceBrighton']}
      >
        <TwitterIcon {...iconProps} />
      </TwitterShareButton>
      <RedditShareButton
        url={shareUrl}
        title={title}
        windowWidth={660}
        windowHeight={460}
        content={body}
      >
        <RedditIcon {...iconProps} />
      </RedditShareButton>
      <EmailShareButton
        url={shareUrl}
        subject={'Leave No Trace Brighton'}
        body={body}
      >
        <EmailIcon {...iconProps} />
      </EmailShareButton>
      <WhatsappShareButton url={shareUrl} title={bodyWithHashtags} separator=''>
        <WhatsappIcon {...iconProps} />
      </WhatsappShareButton>
      <TelegramShareButton url={shareUrl} title={bodyWithHashtags}>
        <TelegramIcon {...iconProps} />
      </TelegramShareButton>
    </Stack>
  );
};

export const PledgeModal = ({ isOpen, onOpen, onClose }: ModalProps) => {
  return (
    <Modal
      size={{ base: 'full', sm: 'lg', md: 'lg', lg: 'lg' }}
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay
        bg='blackAlpha.600'
        backdropFilter='auto'
        backdropInvert='20%'
        backdropBlur='8px'
        backdropSaturate={'0.6'}
      />
      <ModalContent
        className='modal-content'
        backgroundColor={'#a0b1b3'}
        border='8px solid #c9cdcd'
        display={'flex'}
        flexDirection={'column'}
        alignContent={'center'}
        alignItems={'center'}
        paddingTop='2rem'
        paddingBottom='1rem'
      >
        <Stack
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          spacing={'1rem'}
        >
          <ModalHeader>
            <Heading
              fontSize={{
                base: '26px',
                sm: '28px',
                md: '30px',
                lg: '32x',
              }}
              textAlign={'center'}
              mb={'6'}
              color={'#1a1a24'}
            >
              Thank you for taking action for the environment!
            </Heading>
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody className='modal-body'>
            <Stack spacing='4'>
              <Box
                w='100%'
                bg='#ebebeb'
                border={'1px solid #1a1a24'}
                padding={'20px'}
                borderRadius={'20px'}
              >
                <Heading
                  fontSize={{
                    base: '22px',
                    sm: '24px',
                    md: '26px',
                    lg: '30x',
                  }}
                  fontWeight={'700'}
                  color='#43425e'
                  mb={'4'}
                >
                  You pledged to:
                </Heading>
                <UnorderedList>
                  <ListItem>
                    <Text
                      fontSize={{
                        base: '18px',
                        sm: '20px',
                        md: '22px',
                        lg: '24x',
                      }}
                    >
                      Disposing your rubbish responsibly
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text
                      fontSize={{
                        base: '18px',
                        sm: '20px',
                        md: '22px',
                        lg: '24x',
                      }}
                    >
                      Carrying and using a reusable cup
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text
                      fontSize={{
                        base: '18px',
                        sm: '20px',
                        md: '22px',
                        lg: '24x',
                      }}
                    >
                      Clearing 3 piece of rubbish on every beach visit
                    </Text>
                  </ListItem>
                </UnorderedList>
              </Box>

              <Heading
                fontSize={{ base: '22px', sm: '24px', md: '26px', lg: '30x' }}
                fontWeight={'bold'}
                mt={'2rem'}
                mb={'1rem'}
                color={'#1a1a24'}
                textAlign={'center'}
              >
                Spread the word!
              </Heading>
              <Text
                fontSize={{ base: '18px', sm: '20px', md: '22px', lg: '24x' }}
                textAlign={'center'}
              >
                You can help the Leave No Trace movement further by spreading
                the word around.
              </Text>
              <Text
                fontSize={{ base: '18px', sm: '20px', md: '22px', lg: '24x' }}
                textAlign={'center'}
              >
                Use one of the buttons below to easily share this page with your
                friends and family. Take the pledge together!
              </Text>
              {renderShareButtons()}
            </Stack>
          </ModalBody>
        </Stack>

        <ModalFooter>
          <Button
            bg='transparent'
            color='#505a5c'
            textDecoration={'underline'}
            fontWeight={'bold'}
            onClick={onClose}
            fontSize={'2xl'}
            mt='1rem'
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
