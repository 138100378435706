import {
  Text,
  Card,
  Heading,
  Box,
  Container,
  Stack,
  useSteps,
  Image,
  Divider,
} from '@chakra-ui/react';
import CaptionCarousel from './Carousel/CaptionCarousel';
import { CarouselStepper } from './Carousel/Stepper';
import CrabIllustration from '../static/images/crab.png';
import ReusableCupImage from '../static/images/reusable-cup.png';
import CampaignImage from '../static/images/take3forthesea.jpg';
import Pledge from './Pledge';

type Card = {
  title: string;
  text: string;
  image?: string;
  imageAlt?: string;
};

const renderCarouselCards = () => {
  const cardContent: Card[] = [
    {
      title: 'Disposing my rubbish responsibly.',
      text: 'To prevent ocean pollution I will dispose of my rubbish into a bin with capacity (recycling where possible). If no bin with capacity is available, I will take my rubbish home',
      image: CrabIllustration,
      imageAlt:
        'Illustration of a seagull with a plastic cup on its head, with Leave No Trace Brighton logo swimming on sea in the background',
    },
    {
      title: 'Carrying and using a reusable cup.',
      text: 'To reduce waste I will use my (clean) reusable cup for purchasing coffee or other hot drinks during my stay in Brighton',
      image: ReusableCupImage,
      imageAlt: '',
    },
    {
      title: 'Taking #3FORTHESEA',
      text: "To prevent ocean pollution I will collect, clear and dispose of 3 items of rubbish, each and every time I visit one of Brighton's beaches or green spaces",
      image: CampaignImage,
      imageAlt: '',
    },
  ];

  return cardContent.map((card, index) => (
    <Box key={index} position='relative'>
      {/* This is the block you need to change, to customize the caption */}
      <Container
        height={{
          base: '520px',
          sm: '620px',
          md: '720px',
          lg: '800px',
        }}
        position='relative'
        padding={0}
      >
        <Stack
          position='absolute'
          top='50%'
          transform='translate(0, -50%)'
          backgroundColor={'#d4e2d1'}
          borderRadius={'20px'}
          paddingX='30px'
          paddingY='30px'
          w={{ base: '90%', md: '90%', lg: '80%' }}
          marginLeft={{ base: '5%', md: '5%', lg: '10%' }}
        >
          <Image src={card.image} alt={card.imageAlt} maxWidth={'100%'} />
          <Heading
            fontSize={{ base: '16px', sm: '24px', md: '26px', lg: '32x' }}
            marginTop='1.5rem'
          >
            {card.title}
          </Heading>
          <Text
            fontSize={{ base: '16px', sm: '18px', md: '22px', lg: '32x' }}
            color='GrayText'
          >
            {card.text}
          </Text>
        </Stack>
      </Container>
    </Box>
  ));
};

const Content = () => {
  const { activeStep, goToNext, goToPrevious, setActiveStep } = useSteps({
    index: 0,
    count: 3,
  });

  const handleSwipe = (direction: string) => {
    if (direction === 'right') {
      if (activeStep > 0) {
        goToPrevious();
      } else if (activeStep === 0) {
        setActiveStep(2);
      }
    } else if (direction === 'left') {
      if (activeStep < 2) {
        goToNext();
      } else if (activeStep === 2) {
        setActiveStep(0);
      }
    }
  };

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      paddingY='2rem'
      className='content'
    >
      <Heading
        fontSize={{ base: '24px', sm: '24px', md: '28px', lg: '44x' }}
        lineHeight={{ base: '34px', sm: '34px', md: '44px', lg: '48px' }}
        color={'#d4e2d1'}
        textAlign={'center'}
        fontWeight={'medium'}
        letterSpacing={{ base: '7px', md: '6px', lg: '7px' }}
        mt='2rem'
      >
        WELCOME TO
      </Heading>
      <Heading
        fontSize={{ base: '42px', md: '48px', lg: '54x' }}
        lineHeight={{ sm: '46px', md: '52px', lg: '58px' }}
        color={'#d4e2d1'}
        textAlign={'center'}
      >
        BRIGHTON
      </Heading>

      <Image
        // src={require('../static/images/seagull.gif')}
        src={require('../static/images/lnt-logo.jpg')}
        w='60%'
        maxWidth='500px'
        borderRadius={'20px'}
        // border={'4px solid #506d71'}
        mt='10'
        mb='4'
      />

      <Text
        mt='8'
        mb='8'
        textAlign={'center'}
        w={'85%'}
        fontSize={{ base: '16px', sm: '20px', md: '24px', lg: '32x' }}
        lineHeight={{ base: '22px', sm: '26px', md: '30px', lg: '36px' }}
        color={'#d4e2d1'}
        fontWeight={'500'}
        marginBlock={'1rem'}
        paddingX={'1rem'}
        fontFamily={'monospace'}
      >
        Join us and take action for Brighton's coastal environment today!
      </Text>

      {/* <Divider mt='10' mb='4' borderColor={'#315356'} /> */}

      <Box
        backgroundColor={'#fcbf49'}
        w={{ base: '90%', md: '85%', lg: '60%' }}
        maxW={'930px'}
        marginY='2rem'
        paddingY='2rem'
        borderRadius={'4rem'}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        gap='1rem'
      >
        <Heading
          fontFamily={'Caprasimo'}
          fontSize={{ base: '22px', md: '26px', lg: '32px' }}
          lineHeight={{ sm: '26px', md: '30px', lg: '36px' }}
          fontWeight={'500'}
          textAlign={'center'}
          paddingX='15%'
          color={'#284245'}
          mt='2'
        >
          <span style={{ textDecoration: 'underline' }}>I pledge to</span> take
          action and support Brighton's coastal environment by:
        </Heading>
        <CaptionCarousel
          Cards={renderCarouselCards()}
          handleSwipe={handleSwipe}
        />
        <CarouselStepper activeStep={activeStep} />
      </Box>

      <Text
        textAlign={'center'}
        w={'85%'}
        fontSize={{ base: '16px', sm: '20px', md: '24px', lg: '32x' }}
        lineHeight={{ base: '22px', sm: '26px', md: '30px', lg: '36px' }}
        color={'#d4e2d1'}
        fontWeight={'500'}
        marginBlock={'1rem'}
        paddingX={'1rem'}
        fontFamily={'monospace'}
        // letterSpacing={'-1px'}
      >
        These three small actions made collectively, will go a long way to
        reducing waste and marine pollution, and to preserving and protecting
        Brighton's coastal environment and all its inhabitants.
      </Text>

      <Pledge />
    </Box>
  );
};

export default Content;
