import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

let authenticated = false;
const firebaseConfig = {
  apiKey: 'AIzaSyCgvBIURsgOb0JLhnsnvLozy-fOO95c3tU',
  authDomain: 'leave-no-trace.firebaseapp.com',
  databaseURL:
    'https://leave-no-trace-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'leave-no-trace',
  storageBucket: 'leave-no-trace.appspot.com',
  messagingSenderId: '457637248324',
  appId: '1:457637248324:web:ed971a8870c6b25f4b2b11',
};

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app();
}

firebase
  .auth()
  .signInAnonymously()
  .then(() => {
    authenticated = true;
  })
  .catch((error: any) => {
    authenticated = false;
    console.error(error);
  });

export const database = firebase.firestore();
export const countRef = database.collection('pledges').doc('count');
