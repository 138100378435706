import { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { Checkbox, Button, Text, Box } from '@chakra-ui/react';
import { database as db, countRef } from './db';
import { PledgeModal } from './PledgeModal';

const Pledge = () => {
  const [isAccepted, setIsAccepted] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [pledgeCount, setPledgeCount] = useState<number | undefined>(undefined);

  useEffect(() => {
    countRef
      .get()
      .then((doc: any) => {
        if (doc.exists) {
          setPledgeCount(doc.data().pledgeCount);
        }
      })
      .catch((error: any) => {
        console.log('Error getting document:', error);
      });
  }, []);

  const handlePledgeConfirm = () => {
    if (pledgeCount !== undefined) {
      const now = new Date();

      const pledgesRef = db.collection('pledges').doc(pledgeCount.toString());
      const increment = firebase.firestore.FieldValue.increment(1);

      const batch = db.batch();
      batch.set(pledgesRef, { date: now.toString() });
      batch.set(countRef, { pledgeCount: increment }, { merge: true });
      batch.commit();

      setPledgeCount(pledgeCount + 1);
      setIsConfirmed(true);
    }
  };

  return (
    <Box
      paddingY='4'
      height={'100%'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      mt='8'
    >
      <Checkbox
        iconColor='#d4e2d1'
        color={'#d4e2d1'}
        colorScheme='teal'
        size={'lg'}
        isChecked={isAccepted}
        onChange={(e) => {
          e.preventDefault();
          setIsAccepted(e.target.checked);
        }}
        className='checkbox'
      >
        <Text color={'#d4e2d1'} fontSize={{ sm: '1xl', md: '1xl', lg: '2xl' }}>
          Click here to take the pledge
        </Text>
      </Checkbox>
      <Button
        isDisabled={!isAccepted}
        colorScheme='teal'
        size='md'
        backgroundColor={'#fcbf49'}
        textColor={'#284245'}
        fontWeight={'bold'}
        onClick={handlePledgeConfirm}
        marginY='1rem'
        w={'7.5rem'}
      >
        Confirm
      </Button>
      {pledgeCount !== undefined ? (
        <Text color={'#d4e2d1'}>
          {pledgeCount} people took the pledge so far
        </Text>
      ) : null}
      <PledgeModal
        isOpen={isConfirmed}
        onClose={() => {
          setIsConfirmed(false);
          setIsAccepted(false);
          // window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
      />
    </Box>
  );
};

export default Pledge;
