import { ChakraProvider } from '@chakra-ui/react';

import Content from './Content';
import { Footer } from './Content/Footer';

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

const App = () => {
  return (
    <ChakraProvider>
      <Content />
      <Footer />
    </ChakraProvider>
  );
};

export default App;
